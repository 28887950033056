// Import site utilities.
@import '../../global/utils/init';

.block-views-services-block {
	background-color: #f4eee9;
	.view-services {
		@include md {
			padding:0 0 50px;
		}
		.views-grid-box {
			@include md {
				padding: 0;
			}
		}
		.view-content {
			img {
				width: auto;
			}
			.field-content {
				display: flex;
			    flex-direction: column;
			    @include md {
			    	align-items: center;
			    }
				.image {
					height: 90px;
					width: 100px;
					@include md {
						text-align: center;
					}
				}
				.service-content {
					padding-bottom: 40px;
					text-align: left;
					@include md {
						padding-bottom: 20px;
					}
					.title {
						color: #000000;
						cursor: default;
						font-weight: 600;
						font-size: 22px;
						line-height: 1em;
						margin-top: 15px;
						padding-bottom: 20px;
						@include md {
							text-align: center;
						}
						&:hover {
							color: #6dc8be;
							-webkit-transition: all 1s;
							transition: all 1s;
						}
					}
					.body {
						text-align: left;
						color: #000000;
						@include md {
							text-align: center;
						}
					}
				}
			}
		}
	}
}
