// Import site utilities.
@import '../../global/utils/init';

.l-footer {
	background: #014968;
	border: 0;
	color: #ffffff;
	display: grid;
	font-size: 18px;
	line-height: 1em;
	grid-template-columns: 1fr 1fr 1fr;
	margin-top: auto;
	place-items: center;
	padding: 0px;
	padding-top: 20px;
	text-align: center;
	@include md {
		display: block;
	}
	.block-system-powered-by {
		.block-content {
			span {
				a {
					color: #ffffff;
					text-decoration: underline;
					&:hover{
						color: #6dc8be;
						transition: all 1s; 
						-webkit-transition: all 1s;
					}
				}
			}
		}
		@include md {
			margin-bottom: 15px;
		}
	}
}