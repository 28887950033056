// Import site utilities.
@import '../../global/utils/init';

.block-views-portfolio-block {
	@include md {
		padding-bottom: 0px!important;
	}
	.view-portfolio {
		position: relative;
		padding: 0 0 20px;
		.view-header {
			color: black;
			padding: 0;
			text-align: center;
		}
		.view-content {
			width: 100%;
			.views-view-grid {
				gap:20px;
				.views-grid-box {
					border: 1px solid rgba(216,228,213,.52);
					box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
				}
				.views-field-field-portfolio-image {
					overflow: hidden;
					img {
					    vertical-align: top;
					    width: 100%;
						-webkit-transition: all 1s;
					    transition: all 1s;
						&:hover {
							transform: scale(1.1);
						}
					}
				}
				.views-field-title {
					color: #000000;
				    padding: 10px;
				}
			}
		}
		.view-footer {
			    display: flex;
			    width: 100%;
			    justify-content:center;
			    padding-top: 20px;
			p {
				padding-top: 30px;
				a {
					background-color: #014968;
					border-radius: 50px;
				    border: 2px solid #014968;
				    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
					color: #ffffff !important;
					font-size: 16px;
					padding: 12px 40px;
					text-decoration: none;
				    -webkit-transition: all .3s ease;
				    transition: all .3s ease;  
					&:hover {
						background-color: rgba(216,228,213,.52);
						color: #000000 !important;
						-webkit-transition: all 1s;
						transition: all 1s;
					}
				}
			}
		}
	}
}

.node-type-portfolio{

	.l-container {
		max-width: 700px;
		margin: 0 auto;

		.field-name-body {
			padding: 15px 0;
		}

		.field-name-field-portfolio-image {
			margin-bottom: 15px;
		}
		@include md {
			padding: 0 15px;
		}
	}
}

.portfolio-page {
	padding: 100px 0;
	.views-view-grid-cols-3 {
		.views-grid-box {
			padding: 0;
			margin: 1rem;
			border: 1px solid #80808038;

			.views-field-title {
				padding: 15px;

				a {
					color: #000000;
				    font-weight: 600;
				    font-size: 22px;
				    &:hover {
						color: #6dc8be;
						transition: all 1s;
				    }
				}
			}

			.views-field-body {
				padding: 0 15px 15px;
			}
		}
		@include lg {
			grid-template-columns: repeat(2, 1fr);
		}
		@include md {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	@include lg {
		padding: 50px 0;
	}
}