// Import site utilities.
@import '../../global/utils/init';

.block-block-about-triplo {
	background-color: rgba(244, 244, 244, 0.68);
	@include md {
		padding: 15px !important;
	}

	.block--inner-wrapper {
		padding: 50px 0;
		.block-content {
			h5 {
				font-size: 20px;
				font-style: italic;
			}
			.header {
				font-size: 38px;
				margin: 0;
				padding: 10px 0px;
				@include md {
					line-height: 1.2em;
				}

			}
			p{
				padding-top: 10px;
				padding-bottom: 30px;
				@include md {
					padding-bottom: 0px;
				}
			}
			.button {
				border: 0;
				background: none;
				display: flex;
				justify-content: center;
				padding-top: 0px;
				padding-bottom: 0px;
				@include md {
					width: 100%;
				    font-size: 0px;
				    padding: 0px 10px;
					}
					@include sm {
						padding: 0px 0px;
					}
				a {
				 	background-color: #014968;
					border-radius: 50px;
				    border: 2px solid #014968;
				    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
					color: #ffffff !important;
					font-size: 16px;
					padding: 0px 40px;
					text-decoration: none;
				    -webkit-transition: all .3s ease;
				    transition: all .3s ease; 
				    @include md {
				    	padding: 0px 21px;
				    } 
				    @include sm {
				    	padding: 0px 12px;
				    	font-size: 15px;
				    }
					&:hover {
						background-color: rgba(216,228,213,.52);
						color: #000000 !important;
						-webkit-transition: all 1s;
						transition: all 1s;
					}
				}
			}
			@include md {
				text-align: center;
			}	
		}	
	}
}

@include md {
	.front {
		.layout-front {
			.block {
				padding: 0px;
			}
		}
	} 	
}