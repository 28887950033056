// Import site utilities.
@import '../../global/utils/init';

.l-header {
	box-shadow: 0 0 5px;
    background: #014968;
    width: 100%;
    z-index: 999;
    max-width: 100vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @include md {
    	display: block;
    }
    .block-system-header {
    	flex-basis:700px;
    }
    .block-system-main-menu {
    	@include xl {
    		width: 100%;
    	}
    	.menu {
    		@include xl {
    			float: right;
    		}
    		@include md {
    			float: left;
    			padding: 15px 0;
    		}
	    	li {
	    		border: 0;
				margin-top: 10px;
				a {
					color: #ffffff;
					font-size: 16px;
					font-weight: 600;
					padding: 0px 20px;
					padding: 30px;
					text-decoration: none !important;
					@include lg {
						padding: 20px;
					}
					@include md {
						padding: 5px;
					}
					&:after {
					    background: #cf5840;
					    content: ' ';
					    display: block;
					    height: 2px;
					    transition: width .2s;
					    width: 0%;
					    margin-top: 5px;
					}
					&:hover {
						background: none;
						padding: 30px;
						font-weight: 600;
						color: white;
						@include md {
							padding:5px;
						}
						&:after {
							width: 100%;
						}
						@include md {
							margin-right: 30px;
						}
					}
					&.active {
						&:after {
							width: 100%;
						}
						@include md {
							margin-right: 30px;
						}
					}
				}
				&:hover {
					background-color: unset !important;
					filter: none;
					color: #ffffff;
				}
			}
		}
    }
	.header-site-name-wrapper {
		img {
			max-height: 90px;
			@include md {
				max-width: 200px;
			}
		}
	}
	&.sticky_header {
		position: fixed;
		top: 0;
	    transition: all .7s ease-in;
	    // .menu-toggle-button {
	    // 	top: 15px;
	    // }
		.block-system-main-menu {
			.menu {
				li{
					a{
						@include md {
							padding: 5px;
						}
					}
				}	
			}
		}
	}
}



