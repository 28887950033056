// Import site utilities.
@import '../../global/utils/init';

.page {
	.l-header {
		.block--inner-wrapper {
			max-width: 1140px;
			margin: 0 auto;
		}
	}
	.l-container {
		max-width: 1140px;
		margin: 0 auto;
		
		.view-blog {
			max-width: 1140px;
			margin: 0 auto;
		}
	}
	img {
		width: 100%;
	}
}

.block-views-blog-block-1 {
	padding: 100px 0 !important;
	@include md {
		padding: 50px 0 !important;
	}
	.block--inner-wrapper {
		.view-content {
			box-shadow: 3px 3px 10px 0 rgba(0,0,0,.7);
			@include md {
				box-shadow: none;
			} 
			.views-row{
				margin-bottom: 20px;
				@include md {
					box-shadow: 3px 3px 10px 0 rgba(0,0,0,.7);
				} 
				.field-content {
					display: grid;
					grid-template-columns:50% 50%;
					@include md {
						display: block;
					}
				}
				a {
					color: #000000;
				}
				.button {
					box-shadow: 0 0 2px;
					background: none;
					color: #000000 !important;
					line-height: 1em;
					overflow: hidden;
					position: relative;
					padding: 12px 40px;
					text-transform: capitalize;
					z-index: 1;
					&:before {
					    bottom: 0;
					    background-color: rgba(216,228,213,.52);
						content: '';
					    height: 100%;
					    left: 0;
						position: absolute;
					    transition: all .3s;
					    width: 0%;
					    z-index: -1;
					}
					&:after{
					    bottom: 0;
					    border: 1px solid #014968;
						content: '';
					    height: 100%;
					    left: 0;
						position: absolute;
    					transition: all .3s ease;
					    width: 100%;
					    z-index: -2;
					}
					&:hover {
					    color: #000000;
					    text-decoration: none;
					    &:before {
					      width: 100%;
					    }
				  	}
				  	&:hover {
				  		border:0;
				  	}
				}
				img {
					height: 100%;
					max-height: 400px;
					min-height: 400px;
					width: 100%;
					object-fit: cover;	
				}
				&.odd {
					background: #f5d23b;
					.field-content {
						.card-list-left {
							order:1;
							img {
								width: 100%;
							}
						}
						.card-list-right {
							display: grid;
							place-items: center;
							padding: 0 15px;
						    text-align: left;
							.card-list-details {
								max-width: 400px;
								@include md {
									padding: 30px 0;
								}
								.name {
									color: #000000;
									font-size: 22px;
	    							font-weight: 600;	
									text-decoration: none;
									cursor: default;
								}
								.role {
									color: #000000;
									padding-top: 20px;
								}
								.button-content {
									padding-top: 40px;
								}
							}
						}
					}
				}
				&.even {
					background: #cf5840;
					.field-content {
						.card-list-right {
							display: grid;
							place-items: center;
							padding: 0 15px;
						    text-align: left;
							.card-list-details {
								max-width: 400px;
								@include md {
									padding: 30px 0;
								}
								.name {
									color: #000000;
									font-size: 22px;
	    							font-weight: 600;	
									text-decoration: none;
									cursor: default;
								}
								.role {
									color: #000000;
									padding-top: 20px;
								}
								.button-content {
									padding-top: 40px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.blog-page {
	padding: 100px 0;
	@include md {
		padding: 50px 0;
	}
	.view-content {
		.views-grid-box {
			border: 1px solid #80808030;
			border-top: 0;
			margin: 1rem;
			padding: 0;
			.blog-page-content {
				color: #000000;
				padding: 15px;
				.title {
					padding-bottom: 15px;
					a {
					    color: #000000;
					    font-size: 18px;
					    font-weight: 600;
					    &:hover {
					    	color: #6dc8be;
							-webkit-transition: all 1s;
							transition: all 1s;
					    }
					}
				}
			}
		}
		
	}
}

.node-type-blog {
	.l-container{
		max-width: 700px;
		padding: 15px;
		.block-field-node-field-featured-image {
			padding: 30px 0;
		}
	}	
}
