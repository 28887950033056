// Import site utilities.
@import '../../global/utils/init';


.contact_us {
	float: left;
	padding: 50px 0!important;
	margin-right: 100px;
	@include lg {
		margin-right: 0px;
		padding: 15px!important;
	}
	.block-title{
		@include lg {
			text-align: center;
			padding-top: 40px; 
		}
	}
	.form-actions {
		@include lg {
			display: flex;
			justify-content:center;
		}
	}
	.block--inner-wrapper {
		.block-content {
			label {
					color: #22558e;
    				font-size: 20px;
				}
				.form-text  {
					border-radius: 4px;
				}
			.webform-component--name {
				
			}
			.webform-component--message {
				.form-textarea-wrapper {
					textarea {
						border-radius: 4px;
					}	
				}
			}
		}
	}
} 
.block-block-where-are-we {
	padding-top: 110px;
	float: left;
	@include lg {
		float: none;
		text-align: center;
		padding-bottom: 50px;
	}
	.block--inner-wrapper {
		.block-content {
			padding-top: 20px;

		}
	}
}
