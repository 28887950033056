// Import site utilities.
@import '../../global/utils/init';

.view-team {
	background-color: #f4eee9;
	padding: 100px 0;
	@include md {
		padding: 50px 0;
	}
	.views-view-grid {
		gap:25px;
		@include md {
			padding: 15px;
		}
		.views-grid-box {
			padding: 0;
			.views-field-nothing {
				background: white;
				border-top: 0;
				h3 {
					padding: 20px 20px 0;
				}
				p {
					padding: 0 20px;
		    		padding-bottom: 20px;
				}
			}
		}
	}
}